import React from 'react';
import Head from 'next/head';
import Router from 'next/router';
import Link from 'next/link';
import BaseLayout from '../../layouts/BaseLayout';
import LoginCard from '../../components/login';
import Loader from '../../components/app/Loader';
import I18N from '../../services/I18N';
import useAuthListener from '../../hooks/authListener';
import useAuthUser from '../../hooks/authUser';
import usePageQuery from '../../hooks/pageQuery';
import useTranslations from '../../hooks/translations';
import Logger from '../../services/Logger';

import routeConstants from '../../utils/constants/routes';

import styles from './styles.module.scss';
import { checkAuthorizedForFeature } from '../../components/app/RestrictedFeature';
import features from '../../utils/constants/features';
import useTrackPageView from '../../hooks/trackPageView';
import LocaleSwitch from '../../components/app/LocaleSwich';
import { reduxWrapper } from '../../store';
import { setAppLocale } from '../../store/slices/app';
import { getISOLocale } from '../../models/locale';

function LoginPage() {
  const { t } = useTranslations();
  useAuthListener();
  const { user, authLoading } = useAuthUser();
  const { pathname, asPath } = usePageQuery();
  const canAccessPlatform = checkAuthorizedForFeature({
    feature: features.PLATFORM_ACCESS,
    user,
  });
  if (user && user.id) {
    if (canAccessPlatform) {
      if (
        pathname &&
        pathname !== routeConstants.PAGE_LOGIN &&
        pathname !== routeConstants.PAGE_RESTRICTED
      ) {
        Router.push(pathname, asPath);
      } else {
        Router.push(routeConstants.PAGE_DASHBOARD);
      }
    } else {
      Logger.debug('Redirecting to restricted');
      Router.push(routeConstants.PAGE_RESTRICTED);
    }
  }

  useTrackPageView();

  if (canAccessPlatform) {
    return (
      <BaseLayout hideLocaleSwitcher allowMajorLocaleMatching>
        <Loader />
      </BaseLayout>
    );
  }

  return (
    <BaseLayout hideLocaleSwitcher allowMajorLocaleMatching>
      <Head>
        <title key="title">Welcome to Coach Web Platform - Aura</title>
        <meta
          key="description"
          name="description"
          content="Become an Aura Coach. Manage your channels, tracks and view insights on what content users love on Aura."
        />
        <meta
          key="og:title"
          property="og:title"
          content="Welcome to Coach Web Platform - Aura"
        />
        <meta
          key="og:description"
          property="og:description"
          content="Become an Aura Coach. Manage your channels, tracks and view insights on what content users love on Aura."
        />
        <meta
          property="og:url"
          content={`${process.env.NEXT_PUBLIC_APP_DOMAIN}/`}
        />
      </Head>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          padding: '16px 0',
        }}>
        {!authLoading && (
          <div className={styles.head}>
            <Link href={routeConstants.PAGE_SIGNUP} legacyBehavior>
              <a className={styles.registerBtn}>
                {t('onboarding_login_button_register')}
              </a>
            </Link>
            <LocaleSwitch />
          </div>
        )}

        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            flexGrow: 1,
            flexShrink: 1,
          }}>
          <h1 className={styles.title}>{t('welcome_to_aura')}</h1>

          {authLoading ? (
            <Loader style={{ width: 240, height: 240 }} />
          ) : (
            <LoginCard style={{ width: '90%' }} />
          )}
        </div>
      </div>
    </BaseLayout>
  );
}

export const getStaticProps = reduxWrapper.getStaticProps(
  (store) =>
    async ({ locale }) => {
      await store.dispatch(setAppLocale(getISOLocale(locale)));
      return {
        props: {
          ...(await I18N.loadLocale({ locale, route: '/login' })),
        },
      };
    }
);

export default LoginPage;
